<template>
  <div id="app">
    <canvas ref="canvas"></canvas>
  </div>
</template>

<script>
export default {
  name: 'AnimationWave',
  data() {
    return {
      time: 0,
      lineWeight: 50,
      lines: 20,
      ctx: null,
      lastFrameTime: Date.now(),
    }
  },
  methods: {
    draw() {
      const now = Date.now()
      const interval = 1000 / 60 // Targeting 60 fps
      if (now - this.lastFrameTime > interval) {
        this.lastFrameTime = now - ((now - this.lastFrameTime) % interval)
        this.time++
        const { ctx, lines, lineWeight } = this
        const { width, height } = ctx.canvas
        ctx.globalCompositeOperation = 'source-over'
        ctx.fillRect(0, 0, width, height) // Clear with black fill
        ctx.globalCompositeOperation = 'lighter'

        for (let i = 0; i < lines; i++) {
          let r = 200 + 55 * Math.sin(0.4 - i * 0.1 + this.time * 0.05)
          let g = 100 + 55 * Math.sin(i * 0.1 + this.time * 0.1)
          let b = 200 + 55 * Math.sin(this.time * 0.1)
          let a = 100 * Math.sin((Math.PI * i) / lines)
          ctx.strokeStyle = `rgba(${r}, ${g}, ${b}, ${a / 255})`
          ctx.lineWidth = lineWeight

          let p1x = -i - 1 * i * Math.cos(this.time * 0.05)
          let p1y =
            height * 0.5 -
            10 * Math.pow((height * (i + 3)) / lines, 0.5) +
            2 *
              Math.pow(
                (height * 0.5 * (lines - i)) / lines,
                Math.sin((this.time - 20) * 0.02 + 0.2)
              )
          let p2x =
            width / 2 + width * (0.4 * Math.cos(i * 0.01 + this.time * 0.05))
          let p2y =
            height *
              (0.5 + 0.5 * Math.sin(Math.PI * 0.5 + (i * Math.PI) / lines)) -
            500 * Math.pow(Math.sin(i / lines + Math.PI), 6) +
            2 *
              Math.pow(
                (height * 0.5 * (lines - i)) / lines,
                Math.sin((this.time - 15) * 0.02 + 0.2)
              )
          let p3x = width + 5 + 5 * Math.sin(this.time * 0.02 - Math.PI * 0.4)
          let p3y =
            height * 1 -
            Math.pow(
              (height * (i + lines * 0.2)) / lines,
              0.7 + 0.3 * Math.sin(this.time * 0.03 + 0.3)
            )

          ctx.beginPath()
          ctx.moveTo(p1x, p1y)
          ctx.quadraticCurveTo(p2x, p2y, p3x, p3y)
          ctx.stroke()
        }
      }
      requestAnimationFrame(this.draw)
    },
    resizeCanvas() {
      const canvas = this.$refs.canvas
      canvas.width = window.innerWidth
      canvas.height = window.innerHeight
      this.ctx = canvas.getContext('2d')
      this.ctx.fillStyle = 'black'
    },
  },
  mounted() {
    window.addEventListener('resize', this.resizeCanvas)
    this.resizeCanvas()
    this.draw()
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.resizeCanvas)
  },
}
</script>

<style>
#app {
  margin: 0;
  overflow: hidden;
  background: black;
}
</style>

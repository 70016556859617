<template>
  <main class="bg-black h-screen grid place-items-center text-white">
    <div class="w-[100%] h-[100%] hidden"></div>
    <section
      class="w-[1200px] h-[1200px] tab:w-[800px] tab:h-[800px] relative grid place-items-center"
      ref="section"
    >
      <div
        v-for="size in sizes"
        :key="size"
        class="rounded-full absolute border border-neutral-600"
        :style="`width:${size}%;height:${size}%;`"
      ></div>
    </section>
  </main>
</template>

<script setup>
import { gsap } from 'gsap'
import { onMounted, ref } from 'vue'

let section = ref()

let sizes = ref([
  100, 95, 90, 85, 80, 75, 70, 65, 60, 55, 50, 45, 40, 35, 30, 25, 20,
])
onMounted(() => {
  // console.log(section.value)
  // const section = this.$refs.section
  gsap.to(section.value.querySelectorAll('div'), {
    y: -40,
    borderColor: '#f87171',
    ease: 'sine.inOut',
    stagger: { each: 0.05, from: 'end', repeat: -1, yoyo: true },
  })
})
</script>

<style scoped>
section {
  transform: rotateX(50deg);
}
</style>

<template>
  <div class="relative min-h-screen scroll-smooth">
    <div class="px-container relative md:px-containermd">
      <div
        class="flex flex-col items-center self-stretch w-full font-medium text-center"
      >
        <div
          class="w-full flex overflow-hidden relative flex-col items-center self-stretch text-white max-md:px-5 bg-gradient-to-r from-backgroundStart via-backgroundVia to-backgroundStop pb-20"
        >
          <div class="w-full relative overflow-hidden">
            <Parallaxy
              :speed="20"
              :animation="
                (delta) => {
                  return `transform: translateY(${delta * 4}px)`
                }
              "
              class="absolute right-0 top-0 pl-10 md:hidden z-0 transition-all ease-in-out duration-150"
            >
              <img src="@/assets/vector.png" class="w-full" />
            </Parallaxy>
            <img
              src="@/assets/vector-mobile.png"
              class="w-full absolute right-0 top-0 pl-1 hidden md:block z-0"
            />
            <div class="w-full">
              <nav
                class="flex px-20 md:px-5 w-full gap-5 pt-10 justify-between items-center text-sm uppercase"
              >
                <img
                  src="@/assets/logo.png"
                  class="max-w-full md:ml-0 md:justify-start md:max-h-10 z-10"
                />
                <div
                  class="flex rounded-2xl bg-neutral-800 bg-opacity-20 py-2 px-2 top-10 right-20 md:right-0 z-50 fixed md:static md:justify-end"
                >
                  <ul
                    class="top-0 flex gap-10 justify-between items-center py-0 pl-12 rounded-2xl bg-neutral-800 bg-opacity-40 mr-2 md:hidden pr-10"
                  >
                    <li class="self-stretch my-auto relative flex flex-col">
                      <a
                        href="#about-us"
                        class="py-5 flex-grow hover:text-pink-500 cursor-pointer"
                        :class="
                          activeSection === 'about-us' ? 'text-pink-500' : ''
                        "
                        >About Us</a
                      >
                      <div
                        v-if="activeSection === 'about-us'"
                        class="w-full bottom-0 left-0 absolute shrink-0 h-2 bg-pink-500 rounded-xl backdrop-blur-[10.192307472229004px] mt-auto"
                      ></div>
                    </li>
                    <li class="self-stretch my-auto relative flex flex-col">
                      <a
                        href="#values"
                        class="py-5 flex-grow hover:text-pink-500 cursor-pointer"
                        :class="
                          activeSection === 'values' ? 'text-pink-500' : ''
                        "
                        >Values</a
                      >
                      <div
                        v-if="activeSection === 'values'"
                        class="w-full bottom-0 left-0 absolute shrink-0 h-2 bg-pink-500 rounded-xl backdrop-blur-[10.192307472229004px] mt-auto"
                      ></div>
                    </li>
                    <li class="self-stretch my-auto relative flex flex-col">
                      <a
                        href="#criteria"
                        class="py-5 flex-grow hover:text-pink-500 cursor-pointer"
                        :class="
                          activeSection === 'criteria' ? 'text-pink-500' : ''
                        "
                        >Criteria</a
                      >
                      <div
                        v-if="activeSection === 'criteria'"
                        class="w-full bottom-0 left-0 absolute shrink-0 h-2 bg-pink-500 rounded-xl backdrop-blur-[10.192307472229004px] mt-auto"
                      ></div>
                    </li>
                    <li class="self-stretch my-auto relative flex flex-col">
                      <a
                        href="#difference"
                        class="py-5 flex-grow hover:text-pink-500 cursor-pointer"
                        :class="
                          activeSection === 'difference' ? 'text-pink-500' : ''
                        "
                        >Difference</a
                      >
                      <div
                        v-if="activeSection === 'difference'"
                        class="w-full bottom-0 left-0 absolute shrink-0 h-2 bg-pink-500 rounded-xl backdrop-blur-[10.192307472229004px] mt-auto"
                      ></div>
                    </li>
                    <li class="self-stretch my-auto relative flex flex-col">
                      <a
                        href="#touch"
                        class="py-5 flex-grow hover:text-pink-500 cursor-pointer"
                        :class="
                          activeSection === 'touch' ? 'text-pink-500' : ''
                        "
                        >get in Touch</a
                      >
                      <div
                        v-if="activeSection === 'touch'"
                        class="w-full bottom-0 left-0 absolute shrink-0 h-2 bg-pink-500 rounded-xl backdrop-blur-[10.192307472229004px] mt-auto"
                      ></div>
                    </li>
                  </ul>
                  <a href="mailto:marketing@redcomm.co.id" class="">
                    <button
                      class="flex gap-4 self-stretch px-8 py-5 rounded-xl bg-neutral-800 cursor-pointer hover:bg-pink-500 hover:text-black"
                    >
                      <img
                        loading="lazy"
                        src="@/assets/mail.svg"
                        class="shrink-0 aspect-square w-[21px]"
                      />
                      <span class="my-auto uppercase"> Contact Us</span>
                    </button>
                  </a>
                </div>
              </nav>
            </div>
            <Parallaxy
              :speed="20"
              :animation="
                (delta) => {
                  return `transform: translateY(${-delta * 4}px)`
                }
              "
              class="transition-all ease-in-out duration-150 -mt-[60px]"
            >
              <div class="w-full mt-[150px] md:mt-[75px] z-10">
                <h1
                  class="w-full relative text-[90px] md:text-[60px] md:leading-none"
                >
                  Fueling Indonesia's AI Ambition
                </h1>
                <p
                  class="relative mt-8 uppercase w-full text-[40px] md:text-[25px] md:px-[20%]"
                >
                  Where Vision Meets Execution
                </p>

                <div
                  class="w-full flex relative gap-5 py-2 pr-2 pl-16 md:pl-0 mt-16 md:mt-12 text-[20px] uppercase items-center justify-center cursor-pointer"
                >
                  <a href="mailto:marketing@redcomm.co.id">
                    <div
                      class="flex justify-between gap-2 rounded-2xl bg-black bg-opacity-20 p-2 group cursor-pointer"
                    >
                      <div
                        class="mx-auto flex bg-black bg-opacity-40 rounded-2xl group-hover:bg-pink-500"
                      >
                        <span class="my-5 mx-20 md:mx-10 md:my-3"
                          >Get In Touch</span
                        >
                      </div>
                      <div
                        class="p-1 bg-black bg-opacity-30 rounded-2xl group-hover:bg-pink-500"
                      >
                        <img
                          loading="lazy"
                          src="@/assets/send.svg"
                          class="w-[21px] m-5 md:m-3"
                        />
                      </div>
                    </div>
                  </a>
                </div>

                <div
                  class="flex relative gap-4 mt-5 font-semibold text-white text-opacity-60 w-full mx-auto items-center justify-center"
                >
                  Supported by:
                  <img
                    src="@/assets/logo-aws.svg"
                    class="ml-0 md:w-12 opacity-90 w-14"
                  />
                </div>

                <div class="relative">
                  <p
                    class="w-full relative mt-7 md:mt-16 text-xl md:text-sm font-semibold uppercase text-[#FFFFFF] text-opacity-60"
                  >
                    Discover More
                  </p>
                  <div class="w-full mx-auto">
                    <img
                      src="@/assets/arrow-down.png"
                      class="relative mx-auto md:w-7 animate-bounce"
                    />
                  </div>
                </div>
              </div>
            </Parallaxy>
            <div id="about-us">
              <div
                class="w-full text-center px-[10%] md:px-[6%] mt-[350px] pt-20 md:pt-10 md:mt-[150px] text-5xl tracking-[-0.15 rem] text-purple1 md:text-[27px] leading-tight"
              >
                Welcome to the nexus of AI innovation in Indonesia.
                <span class="text-purple2">
                  <br />
                  <br />
                  <span class="pt-20">
                    RedAI Ventures stands at the forefront, uniting visionary
                    tech with unwavering support to transform ambitious ideas
                    into impactful realities.
                  </span>
                  <br />
                  <br />
                  <span>
                    Discover how your startup can redefine technological
                    boundaries and lead the AI revolution, in Indonesia and
                    beyond.
                  </span></span
                >
              </div>

              <div class="w-full px-[70px] md:px-[5%]">
                <div
                  class="w-full flex justify-center items-center px-0 md:px-4 py-20 md:py-10 mt-32 md:mt-32 mb-12 backdrop-blur-[25px] bg-neutral-800 rounded-[50px]"
                >
                  <div class="flex flex-col items-center mt-8 mb-9 w-full">
                    <img
                      loading="lazy"
                      src="@/assets/core.png"
                      class="max-w-full aspect-[1.52] w-[451px] md:w-[250px]"
                    />
                    <h2 class="mt-12 text-4xl uppercase md:text-[25px]">
                      Our Core: AI First
                    </h2>
                    <p
                      class="self-stretch mt-10 text-[78px] md:text-[38px] px-64 tab:px-[20px] md:px-0 leading-tight md:leading-none tracking-tighter bg-gradient-to-r from-gradientPurpleStart via-gradientPurpleVia to-gradientPurpleStop text-transparent bg-clip-text"
                    >
                      Pioneering Growth, Nurturing Innovation For Indonesia's AI
                      Future
                    </p>
                    <p
                      class="mt-16 text-4xl md:text-[23px] px-48 md:px-0 md:leading-tight tracking-[-0.015 rem] text-grey1"
                    >
                      In the heart of Indonesia's tech evolution, RedAI Ventures
                      champions AI-driven startups.<br /><br />
                      <span class="text-neutral-200"
                        >Our focus is laser-sharp:</span
                      >
                      to back Indonesian early ventures where AI isn't just an
                      aspect but the core. We're about setting foundations that
                      future industries will build upon, fostering breakthroughs
                      that matter.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="w-full bg-black text-white">
          <div id="values">
            <div
              class="w-full flex flex-col items-center px-96 md:px-0 tab:px-20"
            >
              <h1
                class="mt-52 md:mt-32 text-[40px] md:text-[25px] md:px-5 text-center text-white uppercase w-full"
              >
                Comprehensive Support, Strategic Impact, Profitability Driven
              </h1>
              <h2
                class="mt-16 md:mt-8 text-[75px] md:text-[38px] md:px-10 md:leading-none font-medium tracking-tighter text-center w-full bg-gradient-to-r from-gradientPurpleStart via-gradientPurpleVia to-gradientPurpleStop text-transparent bg-clip-text"
              >
                Investment Balancing Growth with Profitability
              </h2>
            </div>

            <div class="mt-24 w-full flex md:flex-col px-[70px] md:px-[5%]">
              <div class="flex flex-col w-6/12 md:w-full p-5 md:p-0 md:mb-10">
                <div
                  class="flex flex-col grow px-0 md:px-4 py-10 md:py-14 w-full text-2xl font-medium tracking-[0.015 rem] text-center bg-white backdrop-blur-[25px] rounded-[50px] text-neutral-700"
                >
                  <img
                    loading="lazy"
                    src="@/assets/capital.png"
                    class="self-center w-[60%] md:w-full h-56 md:h-40 aspect-[1.03] px-8"
                  />
                  <p class="mt-10 px-12 md:px-0 text-[#999999] md:text-[23px]">
                    RedAI Ventures offers more than capital;
                    <br />
                    <br />
                    <span class="text-neutral-700"
                      >we provide a strategic partnership for operational
                      excellence, network expansion, mentorship, and
                      companionship through our community of established
                      entrepreneurs with 100+ years of experience combined in
                      tech, marketing, and creative industry.</span
                    >
                  </p>
                </div>
              </div>
              <div class="flex flex-col w-6/12 md:w-full p-5 md:p-0">
                <div
                  class="flex flex-col grow px-0 md:px-4 py-10 md:py-14 w-full text-2xl font-medium tracking-[0.015 rem] text-center bg-white backdrop-blur-[25px] rounded-[50px] text-neutral-700"
                >
                  <img
                    loading="lazy"
                    src="@/assets/philosophy.png"
                    class="self-center w-52 aspect-[1.03]"
                  />
                  <p class="mt-10 px-12 md:px-0 text-[#999999] md:text-[23px]">
                    Our investment philosophy emphasizes not just scalable
                    growth but also sustainable profitability.
                    <br />
                    <br />
                    <span class="text-neutral-700"
                      >We advocate for a balanced approach, nurturing startups
                      to thrive financially while advancing in their market
                      sectors.
                      <br />
                      <br />
                      By aligning with us, you gain a partner committed to
                      fostering both your market impact and financial health,
                      ensuring a pathway to long-term success and leadership in
                      the AI landscape.</span
                    >
                  </p>
                </div>
              </div>
            </div>

            <div
              class="w-full flex relative flex-col items-center pt-[170px] px-[100px] md:px-[5px] pb-20 md:pb-7"
            >
              <AnimationJelly
                class="w-full absolute top-0 md:justify-center tab:h-full tab:justify-center"
              />

              <div class="md:hidden flex relative flex-col items-center">
                <h4
                  class="relative justify-center px-8 py-2 mt-1.5 text-5xl font-medium tracking-tighter text-center text-rose-500 rounded-2xl border-2 border-solid border-rose-500 border-opacity-50"
                >
                  Capital Funding
                </h4>
                <div class="relative self-start mt-40 w-full max-md:mt-10">
                  <div class="flex gap-5 max-md:flex-col max-md:gap-0">
                    <div
                      class="flex flex-col w-[69%] max-md:ml-0 max-md:w-full"
                    >
                      <div class="relative grow max-md:mt-10">
                        <div class="flex gap-5 max-md:flex-col max-md:gap-0">
                          <div
                            class="flex flex-col w-6/12 max-md:ml-0 max-md:w-full"
                          >
                            <h4
                              class="relative justify-center px-8 py-2 mt-16 text-5xl font-medium tracking-tighter text-center text-rose-500 rounded-2xl border-2 border-solid border-rose-500 border-opacity-50 max-md:px-5 max-md:mt-10 max-md:text-4xl"
                            >
                              Operation Support
                            </h4>
                          </div>
                          <div
                            class="flex flex-col ml-5 w-6/12 max-md:ml-0 max-md:w-full"
                          >
                            <img
                              loading="lazy"
                              src="@/assets/logo-red.png"
                              class="w-[80%] m-auto"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      class="flex flex-col ml-5 w-[31%] max-md:ml-0 max-md:w-full"
                    >
                      <h4
                        class="relative justify-center px-8 py-2 mt-16 text-5xl font-medium tracking-tighter text-center text-rose-500 rounded-2xl border-2 border-solid border-rose-500 border-opacity-50 max-md:px-5 max-md:mt-10 max-md:text-4xl"
                      >
                        Strategic Network
                      </h4>
                    </div>
                  </div>
                </div>
                <h4
                  class="relative justify-center px-8 py-2 mt-64 text-5xl font-medium tracking-tighter text-center text-rose-500 rounded-2xl border-2 border-solid border-rose-500 border-opacity-50"
                >
                  Strategic Mentorship
                </h4>
              </div>

              <div class="hidden md:block w-full z-10 relative flex-col h-full">
                <div class="flex justify-start pl-5">
                  <h4
                    class="relative justify-center p-3 mt-3 text-[32px] font-medium tracking-tighter text-center text-rose-500 rounded-xl border-2 border-solid border-rose-500 border-opacity-50 w-[80%]"
                  >
                    Capital Funding
                  </h4>
                </div>
                <div class="flex justify-end pr-5">
                  <h4
                    class="relative p-3 mt-24 text-[32px] font-medium tracking-tighter text-center text-rose-500 rounded-xl border-2 border-solid border-rose-500 border-opacity-50 w-[80%]"
                  >
                    Strategic Network
                  </h4>
                </div>
                <div class="flex justify-start px-14">
                  <div class="my-24">
                    <img src="@/assets/logo-red.png" class="" />
                  </div>
                </div>
                <div class="flex justify-start pl-2">
                  <h4
                    class="relative p-3 mt-5 text-[32px] font-medium tracking-tighter text-center text-rose-500 rounded-xl border-2 border-solid border-rose-500 border-opacity-50 w-[80%]"
                  >
                    Strategic Mentorship
                  </h4>
                </div>
                <div class="flex justify-end pr-5">
                  <h4
                    class="relative justify-end p-3 mt-24 text-[32px] font-medium tracking-tighter text-center text-rose-500 rounded-xl border-2 border-solid border-rose-500 border-opacity-50 w-[80%]"
                  >
                    Operation Support
                  </h4>
                </div>
              </div>
            </div>
          </div>

          <div id="criteria">
            <div class="w-full flex px-[70px] md:px-5">
              <div class="w-full mt-20 md:mt-14">
                <div
                  class="w-full flex justify-center items-center px-16 py-20 md:p-4 mt-12 pt-10 font-medium text-center rounded-[50px] bg-gradient-to-r from-backgroundStart via-backgroundVia to-backgroundStop"
                >
                  <div class="flex flex-col items-center w-full md:mt-10">
                    <img
                      src="@/assets/vanguard.png"
                      class="aspect-[0.63] w-[223px] md:w-[100px]"
                    />
                    <h3
                      class="mt-12 text-4xl md:text-[25px] md:leading-none text-white uppercase max-md:mt-10"
                    >
                      Selecting the Vanguard of AI
                    </h3>
                    <h2
                      class="self-stretch mt-10 text-7xl md:text-[38px] leading-tight md:leading-none tracking-tighter bg-gradient-to-r from-gradientPurple2Start via-gradientPurple2Via to-gradientPurple2Stop text-transparent bg-clip-text pb-2 md:pb-1"
                    >
                      Criteria Defining Tomorrow's Leaders
                    </h2>
                    <p
                      class="mt-10 md:mb-10 text-4xl md:text-[23px] tracking-[-0.015 rem] leading-tight text-purple-700 max-md:mt-10 px-32 md:px-2 md:leading-tight"
                    >
                      <span class="text-purple-500"
                        >We champion startups that are at the AI vanguard—</span
                      >entities with groundbreaking proprietary tech, validated
                      markets, and robust technical teams. <br /><br />
                      Your stage matters less than your trajectory: seed or
                      early, we're looking for the potential to reshape
                      landscapes and define futures.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div class="w-full">
              <h2
                class="mt-64 md:mt-48 md:px-16 text-7xl md:text-[50px] font-medium tracking-tighter text-center bg-gradient-to-r from-gradientPurpleStart via-gradientPurpleVia to-gradientPurpleStop text-transparent bg-clip-text"
              >
                We Are Looking For
              </h2>
              <section
                class="px-5 mt-20 w-[80%] tab:w-full tab:px-[70px] mx-auto"
              >
                <div class="flex md:flex-col tab:flex-wrap tab:-mx-[15px]">
                  <div
                    class="flex flex-col w-3/12 flex-none md:w-full md:ml-0 md:mb-16 tab:ml-0 px-[15px]"
                  >
                    <div class="flex flex-col grow max-md:mt-10">
                      <div
                        class="flex flex-col justify-center items-start px-16 bg-black rounded-full border-purple-500 border-solid aspect-square border-[3px]"
                      >
                        <img
                          src="@/assets/centered.png"
                          class="m-auto aspect-[1.1] w-[164px]"
                        />
                      </div>
                      <h4
                        class="self-center mt-7 text-4xl font-medium tracking-tighter text-center text-purple-500 w-[247px] tab:whitespace-pre-wrap"
                      >
                        AI-centered Startup
                      </h4>
                    </div>
                  </div>
                  <div
                    class="flex flex-col ml-0 w-3/12 flex-none md:w-full md:ml-0 md:mb-16 tab:ml-0 px-[15px]"
                  >
                    <div class="flex flex-col grow max-md:mt-10">
                      <div
                        class="flex justify-center items-center px-16 bg-black rounded-full border-purple-500 border-solid aspect-square border-[3px] max-md:px-5"
                      >
                        <img
                          src="@/assets/proprietary.png"
                          class="m-auto aspect-[1.14] w-[173px]"
                        />
                      </div>
                      <h4
                        class="self-center mt-7 text-4xl font-medium tracking-tighter text-center text-purple-500 w-[247px] tab:whitespace-pre-wrap"
                      >
                        Proprietary Technologies
                      </h4>
                    </div>
                  </div>
                  <div
                    class="flex flex-col ml-0 w-3/12 flex-none md:w-full md:ml-0 md:mb-16 tab:ml-0 px-[15px]"
                  >
                    <div class="flex flex-col grow max-md:mt-10">
                      <div
                        class="flex justify-center items-center px-16 bg-black rounded-full border-purple-500 border-solid aspect-square border-[3px] max-md:px-5"
                      >
                        <img
                          src="@/assets/seed.png"
                          class="m-auto aspect-[1.49] w-[189px]"
                        />
                      </div>
                      <h4
                        class="self-center mt-7 text-4xl font-medium tracking-tighter text-center text-purple-500 w-[247px]"
                      >
                        Seed to Early Stage Phase
                      </h4>
                    </div>
                  </div>
                  <div
                    class="flex flex-col ml-0 w-3/12 flex-none md:w-full md:ml-0 md:mb-16 tab:ml-0 px-[15px]"
                  >
                    <div class="flex flex-col grow max-md:mt-10">
                      <div
                        class="flex justify-center items-center px-16 bg-black rounded-full border-purple-500 border-solid aspect-square border-[3px] max-md:px-5"
                      >
                        <img
                          src="@/assets/strong.png"
                          class="m-auto aspect-[1.05] w-[173px]"
                        />
                      </div>
                      <h4
                        class="mt-7 text-4xl font-medium tracking-tighter text-center text-purple-500"
                      >
                        Strong Technical Founding Members
                      </h4>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>

          <div class="w-full pb-48" id="difference">
            <AnimationWave class="absolute w-full z-1" />

            <section
              class="flex overflow-hidden relative z-2 flex-col items-center self-stretch px-16 md:px-0 pt-20 md:pt-20 pb-8 mt-52 md:mt-20 w-full font-medium text-center"
            >
              <div class="flex relative flex-col items-center mt-20 md:mt-20">
                <h3
                  class="text-[40px] md:text-[25px] uppercase text-white md:leading-tight md:px-16"
                >
                  Not Just Partners—Catalysts
                </h3>
                <h2
                  class="mt-10 text-[75px] md:text-[38px] tracking-tighter bg-gradient-to-r from-gradientPurpleWhiteStart via-gradientPurpleWhiteVia to-gradientPurpleWhiteStop text-transparent bg-clip-text md:leading-tight md:px-16"
                >
                  Why RedAI Stands Apart
                </h2>
                <p
                  class="self-stretch mt-14 text-4xl tracking-[-0.015 rem] leading-tight text-[#E2C6DD] px-60 md:px-10 md:text-[23px] md:leading-none"
                >
                  <span class="text-white"
                    >With RedAI Ventures, you gain more than a funding
                    source—</span
                  >you access a nexus of insight, support, and industry linkage.

                  <br /><br />
                  Our commitment is to your holistic success, fostering avenues
                  where your startup not only grows but thrives and leads in the
                  AI domain.
                </p>
              </div>
            </section>
          </div>

          <div
            class="w-full relative pt-12 md:pt-10 flex flex-col items-center"
            id="touch"
          >
            <div class="absolute top-0 left-0 z-0 w-full h-full">
              <div
                class="absolute top-0 left-0 z-0 w-full h-full bg-black bg-opacity-50"
              ></div>
              <iframe
                src="https://breathingservice.redasiainc.com/"
                class="w-full h-full"
              ></iframe>
            </div>

            <div class="relative z-10 pb-10">
              <div
                class="flex overflow-hidden relative flex-col items-center self-stretch px-20 md:px-0 pt-20 pb-12 md:pb-0 w-full"
              >
                <h3
                  class="text-4xl md:text-[25px] text-white uppercase max-md:mt-10 md:px-12"
                >
                  Start Your Journey With Us
                </h3>
                <h3
                  class="relative text-7xl font-medium tracking-tighter text-center bg-gradient-to-r from-gradientPurpleStart via-gradientPurpleVia to-gradientPurpleStop text-transparent bg-clip-text py-5 md:text-[38px] md:leading-none"
                >
                  Engage with RedAI Ventures
                </h3>
                <p
                  class="relative pt-8 text-4xl font-medium tracking-[-0.015 rem] leading-tight text-center text-neutral-400 w-full px-[260px] md:text-[23px] tab:px-[120px] md:px-[40px] md:leading-tight"
                >
                  <span class="text-white">
                    Is your startup poised to elevate Indonesia's AI landscape? </span
                  ><br /><br />
                  RedAI Ventures is your gateway to an ecosystem rich with
                  possibility and growth. Apply now, and let's craft the future
                  of AI together, creating benchmarks for innovation and success
                  in Indonesia and beyond.
                </p>

                <a href="mailto:marketing@redcomm.co.id">
                  <div
                    class="mt-20 md:mt-12 relative flex justify-center gap-2 rounded-2xl bg-[#DB4FBF] bg-opacity-20 p-2 uppercase items-center mb-48 md:mb-28 cursor-pointer"
                  >
                    <div
                      class="mx-auto p-1 flex bg-gradient-to-r from-gradientOrangePurpleStart via-gradientOrangePurpleVia to-gradientOrangePurpleStop rounded-2xl"
                    >
                      <span
                        class="m-5 px-10 md:m-3 md:px-5 text-[34px] md:text-[23px] hover:text-black"
                        >Get In Touch</span
                      >
                    </div>
                    <div class="p-1 bg-[#DB4FBF] rounded-2xl">
                      <img
                        loading="lazy"
                        src="@/assets/send.svg"
                        class="w-[32px] m-7 md:m-3"
                      />
                    </div>
                  </div>
                </a>
              </div>
              <div
                class="flex items-center justify-between w-full text-white px-20 md:px-10"
              >
                <img
                  src="@/assets/logo.png"
                  class="shrink-0 w-44 md:w-24 h-auto justify-start"
                />
                <div
                  class="flex justify-center items-center gap-8 mt-11 text-sm text-center uppercase md:hidden"
                >
                  <a
                    href="#about-us"
                    class="grow hover:text-pink-500 cursor-pointer"
                    >About Us</a
                  >
                  <a href="#values" class="hover:text-pink-500 cursor-pointer"
                    >Values</a
                  >
                  <a href="#criteria" class="hover:text-pink-500 cursor-pointer"
                    >Criteria</a
                  >
                  <a
                    href="#difference"
                    class="hover:text-pink-500 cursor-pointer"
                    >Difference</a
                  >
                  <a href="#touch" class="hover:text-pink-500 cursor-pointer"
                    >get in Touch</a
                  >
                </div>
                <div
                  class="mt-11 text-base tracking-tight text-right md:text-[12px]"
                >
                  © 2024. REDAIVENTURES
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import './assets/styles.css'

import Parallaxy from '@lucien144/vue3-parallaxy'
import AnimationJelly from './components/AnimationJelly.vue'
import AnimationWave from './components/AnimationWave.vue'

import { onMounted, onUnmounted, ref } from 'vue'

const activeSection = ref('')

const observer = new IntersectionObserver(
  (entries) => {
    let found = false
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        activeSection.value = entry.target.id
        found = true
      }
    })

    if (!found) {
      activeSection.value = ''
    }
  },
  {
    root: null,
    rootMargin: '0px', // Adjusts the margin to change the active section when the target is in the middle of the viewport
    threshold: [0.3, 0.6],
  }
)

onMounted(() => {
  document
    .querySelectorAll('#about-us, #values, #criteria, #touch, #difference')
    .forEach((section) => {
      observer.observe(section)
    })

  document.title = `Fueling Indonesia's AI Ambition | REDAI Ventures`
})

onUnmounted(() => {
  document
    .querySelectorAll('#about-us, #values, #criteria, #touch, #difference')
    .forEach((section) => {
      observer.unobserve(section)
    })
})

// export default {

//     return { activeSection }
//   },
// }
</script>
